import awsconfig from "../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import React, { Component } from "react";

import "./Authenticator.css";

Amplify.configure(awsconfig);

export class Authenticator extends Component {
  render() {
    return (
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "Email Address",
              placeholder: "Email",
              required: true,
            },
            {
              type: "password",
              label: "Password",
              placeholder: "Password",
              required: true,
            },
          ]}
        />
      </AmplifyAuthenticator>
    );
  }
}

export default Authenticator;
