import Auth from "@aws-amplify/auth";
import AppBar from "@material-ui/core/AppBar";
import teal from "@material-ui/core/colors/teal";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ExitToApp, Redeem, Search } from "@material-ui/icons";
import { SnackbarProvider, useSnackbar } from "notistack";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { AuthContext } from "./context/AuthContext";
import ConcessionView from "./view/ConcessionView";
import OrderLookupView from "./view/OrderLookupView";
import PromotionView from "./view/PromotionView";

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: teal,
  },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const SignoutButton = () => {
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ListItem button onClick={handleSignOut}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      Sign Out
    </ListItem>
  );
};

export default function AuthenticatedApp(props) {
  const classes = useStyles();
  const authContext = React.useContext(AuthContext);

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider preventDuplicate maxSnack={3} autoHideDuration={3000}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap>
                Customer Service Portal
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className={classes.toolbar} />
            <Divider />
            <List>
              <ListItem button href="/search" component="a">
                <ListItemIcon>
                  <Search />
                </ListItemIcon>
                Orders Search
              </ListItem>
              <ListItem button href="/concession" component="a">
                <ListItemIcon>
                  <Redeem />
                </ListItemIcon>
                Concessions
              </ListItem>
            </List>
            <Divider />
            <List>
              <SignoutButton />
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <BrowserRouter>
              <Switch>
                <Route path="/search" component={OrderLookupView} />
                <Route path="/concession" component={ConcessionView} />
              </Switch>
            </BrowserRouter>
          </main>
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}
