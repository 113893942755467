import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import Amplify from "aws-amplify";
import React from "react";

import Authenticator from "./auth/Authenticator";
import AuthenticatedApp from "./AuthenticatedApp";
import awsconfig from "./aws-exports";
import { AuthContext } from "./context/AuthContext";

import "./App.css";

Amplify.configure(awsconfig);

const App = () => {
  const [authState, setAuthState] = React.useState();
  const [authData, setAuthData] = React.useState(null);

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setAuthData(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && authData ? (
    <AuthContext.Provider value={authData}>
      <React.Fragment>
        <AuthenticatedApp />
      </React.Fragment>
    </AuthContext.Provider>
  ) : (
    <Authenticator />
  );
};

export default App;
