const ENUMS = {
  PERCENT_10_3: "10off3",
  PERCENT_10_1: "10off1",
  PERCENT_15_3: "15off3",
  PERCENT_15_1: "15off1",
  PERCENT_20_3: "20off3",
  PERCENT_20_1: "20off1",
  FLAT_250_MIN_10_3: "freetopping3",
  FLAT_250_MIN_10_1: "freetopping1",
  FLAT_500_MIN_10_3: "2freetopping3",
  FLAT_500_MIN_10_1: "2freetopping1",
  VOUCHER_1000_1: "10voucher1",
  VOUCHER_1500_1: "15voucher1",
  VOUCHER_2000_1: "20voucher1",
  VOUCHER_2000_2: "20voucher2",
  MED_2: "2med",
  MED_2_DRINK_2: "2med2drink",
  MED_2_DRINK_2_TIRAMISU_2: "2med2drink2tiramisu",
  GC_50: "gc50",
  GC_100: "gc100",
};

export const CONCESSIONS = {
  3: [
    {
      concessionId: ENUMS.PERCENT_10_1,
      name: "10% off",
      description: "10% off entire order. One time use",
      units: "1",
    },
    {
      concessionId: ENUMS.FLAT_250_MIN_10_1,
      name: "1 free topping",
      description: "$2.50 off order. Minimum $10 order. One time use.",
      units: "1",
    },
  ],
  2: [
    {
      concessionId: ENUMS.PERCENT_15_1,
      name: "15% off once",
      description: "15% off entire order. One time use",
      units: "1",
    },
    {
      concessionId: ENUMS.FLAT_250_MIN_10_1,
      name: "1 free topping",
      description: "$2.50 off order. Minimum $10 order. One time use.",
      units: "1",
    },
  ],
  1: [
    {
      concessionId: ENUMS.PERCENT_20_1,
      name: "20% off once",
      description: "20% off entire order. One time use",
      units: "1",
    },
  ],
  PROMO: [
    {
      concessionId: ENUMS.MED_2,
      name: "2 Medium Bowls",
      description: "$29 plus tax voucher value",
      units: "1",
    },
    {
      concessionId: ENUMS.MED_2_DRINK_2,
      name: "2 Medium Bowls, 2 Drinks",
      description: "$37 plus tax voucher value",
      units: "1",
    },
    {
      concessionId: ENUMS.MED_2_DRINK_2_TIRAMISU_2,
      name: "2 Medium Bowls, 2 Drinks, 2 Tiramisu",
      description: "$52 plus tax voucher value",
      units: "1",
    },
    {
      concessionId: ENUMS.GC_50,
      name: "$50 Gift Card",
      description: "$50 voucher value, usable on gift cards",
      units: "1",
    },
    {
      concessionId: ENUMS.GC_100,
      name: "$100 Gift Card",
      description: "$100 voucher value, usable on gift cards",
      units: "1",
    },
  ],
};
