import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";

import { AuthContext } from "../context/AuthContext";

const defaultConcessions = require("../util/concessions/defaultConcessions");

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  field: {
    margin: theme.spacing(3),
    width: "30%",
  },
  cardItem: {
    height: "100%",
  },
  active: {
    backgroundColor: theme.palette.primary.light,
  },
  spacing: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const ConcessionItem = (props) => {
  const classes = useStyles();

  const handleConcessionChange = (e) => {
    props.handleConcessionChange(props.concessionId);
  };

  return (
    <Grid item className={classes.spacing}>
      <ButtonBase onClick={handleConcessionChange} className={classes.cardItem}>
        <Card
          className={`${classes.cardItem} ${
            props.active ? classes.active : ""
          }`}
          variant="outlined"
        >
          <CardContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="h6" color="textPrimary">
                {props.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {props.description}
              </Typography>
              <Typography variant="overline" color="textSecondary">
                {`Units: ${props.units}`}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default function PromotionView() {
  const authContext = React.useContext(AuthContext);
  const [concession, setConcession] = React.useState(null);
  const [coupon, setCoupon] = React.useState(null);
  const [generatingState, setGeneratingState] = React.useState(0);
  const classes = useStyles();

  const handleConcessionChange = (concessionId) => {
    setConcession(concessionId);
  };

  const handleGenerateCode = async () => {
    setGeneratingState(1);
    try {
      let response = await axios.post(
        "https://9xqzn2mbu2.execute-api.us-west-2.amazonaws.com/prod/concession/create",
        {
          concession: concession,
        },
        {
          headers: {
            Authorization: authContext.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (response.data.results) {
        setCoupon(response.data.results[0]);
      } else {
        setCoupon({});
      }
      setGeneratingState(2);
    } catch (error) {
      setGeneratingState(3);
    }
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCopy = (event) => {
    event.target.select();
    document.execCommand("copy");
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      preventDuplicate: false,
    });
  };

  return (
    <Container className={classes.root}>
      <Card>
        <Grid container className={classes.root}>
          <Typography className={classes.spacing}>
            Select a promotion to generate
          </Typography>
          <Grid container>
            {defaultConcessions.CONCESSIONS["PROMO"].map((item) => {
              return (
                <ConcessionItem
                  key={item.concessionId}
                  name={item.name}
                  description={item.description}
                  units={item.units}
                  concessionId={item.concessionId}
                  active={item.concessionId == concession}
                  handleConcessionChange={handleConcessionChange}
                ></ConcessionItem>
              );
            })}
          </Grid>
          <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.spacing}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateCode}
              >
                Generate Code
              </Button>
            </Grid>
            {generatingState == 1 ? (
              <React.Fragment>
                <Typography>Generating coupon code...</Typography>
                <div className={classes.fullWidth}>
                  <LinearProgress />
                </div>
              </React.Fragment>
            ) : generatingState == 2 ? (
              <React.Fragment>
                <Grid item className={classes.spacing}>
                  <Typography>
                    Code has been generated below. The terms and conditions for
                    the specific code are also provided for convenience.
                  </Typography>
                </Grid>
                <Grid item className={classes.spacing}>
                  <TextField
                    label="Code (Click to copy)"
                    variant="outlined"
                    value={coupon.code}
                    className={classes.fullWidth}
                    onFocus={handleCopy}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className={classes.spacing}>
                  <TextField
                    label="Terms and Conditions (Click to copy)"
                    variant="outlined"
                    multiline
                    value={coupon.description}
                    className={classes.fullWidth}
                    onFocus={handleCopy}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </React.Fragment>
            ) : generatingState == 3 ? (
              <Typography className={classes.spacing}>
                Error generating code
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
