import {
  Button,
  Card,
  Container,
  FormControl,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  ButtonBase,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import axios from "axios";

import { AuthContext } from "../context/AuthContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";
import AssignmentIcon from "@material-ui/icons/Assignment";

const defaultConcessions = require("../util/concessions/defaultConcessions");

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    margin: theme.spacing(3),
    width: "30%",
  },
  cardItem: {
    height: "100%",
  },
  active: {
    backgroundColor: theme.palette.primary.light,
  },
  spacing: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const ConcessionItem = (props) => {
  const classes = useStyles();

  const handleConcessionChange = (e) => {
    props.handleConcessionChange(props.concessionId);
  };

  return (
    <Grid item className={classes.spacing}>
      <ButtonBase onClick={handleConcessionChange} className={classes.cardItem}>
        <Card
          className={`${classes.cardItem} ${
            props.active ? classes.active : ""
          }`}
          variant="outlined"
        >
          <CardContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="h6" color="textPrimary">
                {props.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {props.description}
              </Typography>
              <Typography variant="overline" color="textSecondary">
                {`Units: ${props.units}`}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default function ConcessionView() {
  const authContext = React.useContext(AuthContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [severity, setSeverity] = React.useState(-1);
  const [concession, setConcession] = React.useState(null);
  const [coupon, setCoupon] = React.useState(null);
  const [generatingState, setGeneratingState] = React.useState(0);
  const classes = useStyles();

  const getSteps = () => {
    return ["Severity Assessment", "Choose Concession", "Generate Coupon Code"];
  };

  const steps = getSteps();
  const [nextDisabled, setNextDisabled] = React.useState(
    new Array(getSteps().length).fill(true)
  );

  const handleNext = () => {
    if (activeStep == steps.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSeverityChange = (event) => {
    setSeverity(event.target.value);
    let updateNextDisabled = [...nextDisabled];
    updateNextDisabled[0] = false;
    setNextDisabled(updateNextDisabled);
  };

  const handleConcessionChange = (concessionId) => {
    setConcession(concessionId);
    let updateNextDisabled = [...nextDisabled];
    updateNextDisabled[1] = false;
    setNextDisabled(updateNextDisabled);
  };

  const handleGenerateCode = async () => {
    setGeneratingState(1);
    try {
      let response = await axios.post(
        "https://9xqzn2mbu2.execute-api.us-west-2.amazonaws.com/prod/concession/create",
        {
          concession: concession,
        },
        {
          headers: {
            Authorization: authContext.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (response.data.results) {
        setCoupon(response.data.results[0]);
      } else {
        setCoupon({});
      }
      setGeneratingState(2);
    } catch (error) {
      setGeneratingState(3);
    }
    let updateNextDisabled = [...nextDisabled];
    updateNextDisabled[2] = false;
    setNextDisabled(updateNextDisabled);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCopy = (event) => {
    event.target.select();
    document.execCommand("copy");
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      preventDuplicate: false,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container className={classes.root}>
            <Typography>
              Before you create a concession, you need to evaluate the impact
              severity of the customer's issue. Please refer to the customer
              complaint criteria matrix for examples of impact severity. Select
              the appropriate impact severity level to continue.
            </Typography>
            <FormControl variant="filled" className={classes.field}>
              <InputLabel id="severity-label">Impact Severity Level</InputLabel>
              <Select
                labelId="severity-label"
                variant="filled"
                value={severity}
                onChange={handleSeverityChange}
              >
                <MenuItem value={3}>Severity 3 (Lowest)</MenuItem>
                <MenuItem value={2}>Severity 2</MenuItem>
                <MenuItem value={1}>Severity 1 (Highest)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      case 1:
        return (
          <Grid container className={classes.root}>
            <Typography>
              Select an appropriate concession given the severity level.
            </Typography>
            <Grid container>
              {severity > -1
                ? defaultConcessions.CONCESSIONS[severity].map((item) => {
                    return (
                      <ConcessionItem
                        key={item.concessionId}
                        name={item.name}
                        description={item.description}
                        units={item.units}
                        concessionId={item.concessionId}
                        active={item.concessionId == concession}
                        handleConcessionChange={handleConcessionChange}
                      ></ConcessionItem>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.spacing}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateCode}
              >
                Generate Code
              </Button>
            </Grid>
            {generatingState == 1 ? (
              <React.Fragment>
                <Typography>Generating coupon code...</Typography>
                <div className={classes.fullWidth}>
                  <LinearProgress />
                </div>
              </React.Fragment>
            ) : generatingState == 2 ? (
              <React.Fragment>
                <Grid item className={classes.spacing}>
                  <Typography>
                    Code has been generated below. The terms and conditions for
                    the specific code are also provided for convenience.
                  </Typography>
                </Grid>
                <Grid item className={classes.spacing}>
                  <TextField
                    label="Code (Click to copy)"
                    variant="outlined"
                    value={coupon.code}
                    className={classes.fullWidth}
                    onFocus={handleCopy}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className={classes.spacing}>
                  <TextField
                    label="Terms and Conditions (Click to copy)"
                    variant="outlined"
                    multiline
                    value={coupon.description}
                    className={classes.fullWidth}
                    onFocus={handleCopy}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </React.Fragment>
            ) : generatingState == 3 ? (
              <Typography>Error generating code</Typography>
            ) : null}
          </Grid>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container className={classes.root}>
      <Card>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={nextDisabled[index]}
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Card>
    </Container>
  );
}
