import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardActions, CardContent, Container, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";

import getSummary from "../util/legacy/details";

const useStylesForOrderItem = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  flexGrow: {
    flexGrow: "1",
  },
  flex: {
    display: "flex",
    width: "100%",
  },
}));

const OrderItem = (props) => {
  const classes = useStylesForOrderItem();

  return (
    <Accordion square={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.flex}>
          <Typography variant="body1" className={classes.flexGrow}>
            {`Qty: ${props.item.quantity} - ${props.item.itemName}`}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {props.item.details.length > 0 ? (
          <List className={classes.root} dense={true}>
            {props.item.details.map((detail, index) => (
              <React.Fragment key={index}>
                <ListItem disableGutters={true}>
                  <ListItemText primary={detail.key} />
                </ListItem>
                {detail.items.map((details, index) => (
                  <ListItem
                    key={index}
                    details={details}
                    className={classes.nested}
                    disableGutters={true}
                  >
                    <ListItemText secondary={details} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

const useStylesForSearchResultItem = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const SearchResultItem = (props) => {
  const classes = useStylesForSearchResultItem();
  const [initials, setInitials] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [placedTime, setPlacedTime] = React.useState();
  const [orderStatusText, setOrderStatusText] = React.useState();
  const [orderObject, setOrderObject] = React.useState();
  const [orderSummary, setOrderSummary] = React.useState();

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getInitials = (name) => {
    let words = name.split(" ");
    if (words.length > 1) {
      return (words[0][0] + words[1][0]).toUpperCase();
    } else {
      console.log(words);
      return words[0][0].toUpperCase();
    }
  };

  React.useEffect(() => {
    setInitials(getInitials(props.item.CUSTOMER_NAME));
    setPlacedTime(
      new Date(props.item.TIMESTAMP).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
      })
    );

    let status = "Completed";
    if (props.item.ORDER_STATUS == "0") {
      status = "Open";
    } else if (props.item.ORDER_STATUS == "1") {
      status = "Accepted";
    }
    setOrderStatusText(status);
    setOrderObject(JSON.parse(props.item.ORDER_JSON));
    setOrderSummary(getSummary(props.item.ORDER_JSON));
  }, [
    props.item.CUSTOMER_NAME,
    props.item.TIMESTAMP,
    props.item.ORDER_STATUS,
    props.item.ORDER_JSON,
  ]);

  return (
    <ListItem>
      <Accordion
        className={classes.root}
        expanded={expanded === props.item.UUID}
        onChange={handleExpand(props.item.UUID)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemAvatar>
            <Avatar>{initials}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Order ID - ${props.item.UUID.substring(
              0,
              8
            ).toUpperCase()}`}
            secondary={
              <div className={classes.flex}>
                <Typography className={classes.flexGrow}>
                  {props.item.CUSTOMER_EMAIL}
                </Typography>
                <Typography variant="subtitle2">
                  {`Placed: ${placedTime}`}
                </Typography>
              </div>
            }
          />
        </AccordionSummary>
        <AccordionDetails>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {orderStatusText}
              </Typography>
              <Typography variant="h5" component="h2">
                {props.item.CUSTOMER_NAME}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {`Pickup Time: ${
                  props.item.PICKUP ? props.item.PICKUP : "ASAP"
                }`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Items:
              </Typography>
              {orderSummary
                ? orderSummary.map((item, index) => (
                    <OrderItem key={index} item={item}></OrderItem>
                  ))
                : null}
            </CardContent>
            <CardActions>
              <Button size="small">Send Receipt</Button>
              <Button size="small">Refund</Button>
              <Button size="small">Transfer</Button>
              <Button size="small">Add Note</Button>
            </CardActions>
          </Card>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

const useStylesForOrderLookupView = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    margin: theme.spacing(3),
    width: "30%",
  },
  button: {
    margin: theme.spacing(3),
  },
}));

export default function OrderLookupView() {
  const classes = useStylesForOrderLookupView();
  const [responseStatus, setResponseStatus] = React.useState();
  const [queryResult, setQueryResult] = React.useState([]);
  const [email, setEmail] = React.useState();
  const [orderId, setOrderId] = React.useState();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOrderIdChange = (event) => {
    setOrderId(event.target.value);
  };

  const doLookup = async () => {
    try {
      const graphqlQuery = `query MyQuery($EMAIL: String = "${email}", $UUID: String = "${orderId.toLowerCase()}") {
        searchLegacyOrder(EMAIL: $EMAIL, UUID: $UUID) {
          items {
            UUID
            COMMENT
            CUSTOMER_EMAIL
            CUSTOMER_NAME
            CUSTOMER_PHONE
            ORDER_JSON
            ORDER_STATUS
            PAYMENT_INTENT
            PICKUP
            STORE
            TIMESTAMP
            TIP
          }
        }
      }
      `;

      let graphqlQueryResult = await API.graphql(
        graphqlOperation(graphqlQuery)
      );

      if (graphqlQueryResult) {
        let items = graphqlQueryResult.data.searchLegacyOrder.items;
        setQueryResult(items);
        setResponseStatus(200);
      }
    } catch (err) {
      setQueryResult([]);
      setResponseStatus(503);
      console.error(err);
    }
  };

  return (
    <Container className={classes.root}>
      <form noValidate autoComplete="off">
        <Typography variant="body1">
          Look up orders based off of email and order ID.
        </Typography>
        <TextField
          required
          id="email"
          onChange={handleEmailChange}
          variant="outlined"
          label="Email"
          placeholder="customer@example.com"
          className={classes.field}
          margin="dense"
        />
        <TextField
          required
          id="order-id"
          onChange={handleOrderIdChange}
          variant="outlined"
          label="Order ID"
          placeholder="ABC123"
          className={classes.field}
          margin="dense"
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={doLookup}
          size="large"
          className={classes.button}
        >
          Search
        </Button>
      </form>

      {responseStatus == 200 ? (
        <List>
          <Typography>{`Search Results (${queryResult.length} items):`}</Typography>
          {queryResult.length > 0 ? (
            queryResult.map((item) => {
              return <SearchResultItem key={item.UUID} item={item} />;
            })
          ) : (
            <Typography variant="subtitle2" align="center">
              No results
            </Typography>
          )}
        </List>
      ) : null}
    </Container>
  );
}
